import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
// import Grid from '@mui/material/Grid';
import { Button } from 'gatsby-theme-material-ui';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import WarningSVG from '../assets/images/warning-orange.svg';
import DialogFAQBeamsplitter from '../components/dialog-faq-beamsplitter';
import ProductUpsellCardBeamSplitter from '../components/upsell-cards/product-upsell-card-beamsplitter';

const WarningBeamSplitterSection = ({
  allStripeProducts,
  adapterKitSelected,
  stripeAdapterKitAdded,
  images,
  ...props
}) => {
  const compatibilityData = useStaticQuery(graphql`
    query {
      brands2Beamsplitter: allBrand2BeamsplitterCompatibilityJson {
        nodes {
          BeamSplitter
          Brand
          Device
          SKU
          Stripe_Product
          kit_product_id
          product_id
        }
      }
    }
  `);
  const brands2Beamsplitter = compatibilityData.brands2Beamsplitter.nodes;

  const [displayWarning, setDisplayWarning] = useState(true);
  const [stripeBeamsplitterKit, setStripeBeamsplitterKit] = useState('');
  const [animation, setAnimation] = useState(true);
  const [displayBeamsplitter, setDisplayBeamsplitter] = useState(true);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    //Runs on the first render
    //And any time any dependency value changes
    try {
      let beamsplitterKit = findMicrorecBeamsplitterKit(adapterKitSelected);
      let item = getMicrorecBeamsplitterKit(beamsplitterKit);
      setStripeBeamsplitterKit(item);
    } catch {
      setDisplayBeamsplitter(false);
    }
  }, [adapterKitSelected]);

  // From all the stripe products retrive the ones that are microrec kit + BS
  const microrecBeamsplitterKitsStripe = allStripeProducts.filter((product) =>
    product.name.includes('.BS.')
  );

  // Since we know the adapterKit that was selected in the MicroREC page, we can find the BeamsplitterKit that matches the brand and device type
  const findMicrorecBeamsplitterKit = (adapterKitSelected) => {
    let microrecBeamsplitterKit = brands2Beamsplitter.find((item) => {
      return (
        item.Brand === adapterKitSelected.Brand &&
        item.Device === adapterKitSelected.Device
      );
    });
    return microrecBeamsplitterKit;
  };

  // Onced we know which BS kit we need, we find the Stripe BS Kit
  const getMicrorecBeamsplitterKit = (beamsplitterKit) => {
    let microrecBeamsplitterKitStripe = microrecBeamsplitterKitsStripe.find(
      (item) => {
        return item.product_id === beamsplitterKit.kit_product_id;
      }
    );
    return microrecBeamsplitterKitStripe;
  };

  return (
    <div>
      {displayWarning ? (
        <Box>
          <Box sx={{ backgroundColor: '#F9F9F9' }}>
            <Container maxWidth={'lg'}>
              <Grid
                container
                // spacing={2}
                marginBottom={2}
                marginTop={3}
                paddingTop={2}
                // paddingLeft={4}
                // paddingRight={4}
                justifyContent={{ xs: 'center', sm: 'flex-start' }}
                // justifyContent={'center'}
              >
                <Grid item xs={10} sm={3}>
                  <WarningSVG />
                </Grid>
                <Grid item xs={10} sm={5}>
                  <Typography
                    variant="h4"
                    // align="center"
                    sx={{
                      fontWeight: 'bold',
                      marginBottom: 4,
                      color: '#FFA100',
                    }}
                    gutterBottom={true}
                    textAlign={'justify'}
                  >
                    {' '}
                    Before you check out!{' '}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ marginBottom: 2 }}
                    textAlign={'justify'}
                    //   gutterBottom={true}
                  >
                    <p>
                      Depending on your microscope or slit lamp you{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        {' '}
                        may need a Beam Splitter{' '}
                      </span>{' '}
                      in order to connect the MicroREC.
                    </p>
                    {displayBeamsplitter ? (
                      <p>
                        Check if you need one and add it to the shopping cart
                        now! Your are not sure? <br />
                        <Button
                          onClick={handleClickOpen}
                          style={{ color: '#FFA100', textDecoration: 'none' }}
                        >
                          Check this page to get more&nbsp;info!
                        </Button>
                      </p>
                    ) : (
                      <p>
                        Currently we don't have a custom beam splitter for you
                        device. Make sure you have one before buying the
                        MicroREC KIT. <br />
                        <Button
                          onClick={handleClickOpen}
                          style={{ color: '#FFA100', textDecoration: 'none' }}
                        >
                          Check this page to get more&nbsp;info!
                        </Button>
                      </p>
                    )}
                  </Typography>
                  <DialogFAQBeamsplitter open={open} setOpen={setOpen} />
                </Grid>
              </Grid>
            </Container>
          </Box>

          {/*  Show BS upsell option to change MicroREC Kit to MicroREC Kit + BS */}
          <Box>
            <Container maxWidth={'lg'}>
              <Grid container padding={3} margin={1} justifyContent={'center'}>
                {displayBeamsplitter ? (
                  <div>
                    <ProductUpsellCardBeamSplitter
                      displayBeamsplitter={displayBeamsplitter}
                      setDisplayBeamsplitter={setDisplayBeamsplitter}
                      displayWarning={displayWarning}
                      setDisplayWarning={setDisplayWarning}
                      animation={animation}
                      setAnimation={setAnimation}
                      name="Beam Splitter"
                      product={stripeBeamsplitterKit}
                      removeProduct={stripeAdapterKitAdded}
                      description="Connect a smartphone to a microscope or slit lamp seamlessly with a
            MicroREC optical beam splitter. It attaches to a microscope or slit
            lamp, captures a beam of light, and directs it to the smartphone
            camera."
                    />
                    {/* </Grid> */}
                  </div>
                ) : // </div>
                null}
              </Grid>
              <Divider style={{ width: '100%' }} />
            </Container>
          </Box>
        </Box>
      ) : null}
    </div>
  );
};

export default WarningBeamSplitterSection;
