import React, { useContext } from 'react';

import { myContext } from '../../utils/provider';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import { Button } from 'gatsby-theme-material-ui';
import { useShoppingCart } from 'use-shopping-cart/react';
import { formatCurrencyString } from 'use-shopping-cart';

import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';

import { addItemToCartEventGA } from '../../utils/ga-events.js';
import { addItemToCartEventFB } from '../../utils/fb-events.js';

const ProductUpsellCardBeamSplitter = ({
  displayBeamsplitter,
  setDisplayBeamsplitter,
  displayWarning,
  setDisplayWarning,
  animation,
  setAnimation,
  product,
  removeProduct,
  name,
  description,
  ...props
}) => {
  // Query Beam Splitter Image
  const beamSplitterImage = useStaticQuery(graphql`
    query BeamSplitterImageQuery {
      file(
        sourceInstanceName: { eq: "images" }
        name: { eq: "Beamsplitter white" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
        name
      }
    }
  `);

  // Get Country IP
  const context = useContext(myContext);

  // Get Currency
  const getCurrency = () => {
    if (context.country === 'US') {
      return 'USD';
    } else {
      return 'EUR';
    }
  };

  // Get our functions from u-s-c
  const { addItem, removeItem, handleCartClick } = useShoppingCart();

  // Handle clicking the add item button
  const handleAddItem = () => {
    // Remove the microRec kit adapter
    removeItem(removeProduct.price_id);
    // Add the microRec kit + Beam splitter
    addItem(product);
    addItemToCartEventGA(product);
    addItemToCartEventFB(product);
    handleCartClick();
  };

  const hide = async (ms) => {
    // setAnimation('close');

    await new Promise((r) => setTimeout(r, ms));

    setDisplayBeamsplitter(false);
    setDisplayWarning(false);
    // setting display warning to false closes the cart
  };

  const handleClick = () => {
    hide(1000);
    handleAddItem();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4} md={3}>
        <GatsbyImage
          image={getImage(beamSplitterImage.file)} fadeIn={false} loading="eager"
          key={beamSplitterImage.file.name}
          imgStyle={{
            borderRadius: '5px',
          }}
          style={{
            borderRadius: '5px',
            boxShadow: ' 1px 1px 1px 2px rgba(0, 0, 0, 0.05)',
          }}
        />
      </Grid>
      <Grid container item xs={12} sm={6} md={4} spacing={1}>
        <Grid container item spacing={2} justifyContent={'space-between'}>
          <Grid item>
            <Typography variant="h6"> {name} </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6">
              +
              {formatCurrencyString({
                value: product.price - removeProduct.price,
                currency: getCurrency(),
              })}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="body1" textAlign={'justify'}>
            {description}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            onClick={handleClick}
            color="primary"
            variant="contained"
            endIcon={<AddShoppingCartOutlinedIcon />}
          >
            Add to MicroREC KIT
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductUpsellCardBeamSplitter;
