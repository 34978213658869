import React from 'react';
// import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ProductUpsellCard from '../components/upsell-cards/product-upsell-card';
import ProductUpsellCardWithDropdown from '../components/upsell-cards/product-upsell-card-with-dropdown';

import { useStaticQuery, graphql } from 'gatsby';

const ProductsUpsellSection = ({ allStripeProducts, images, ...props }) => {
  const compatibilityData = useStaticQuery(graphql`
    query {
      brands2Beamsplitter: allBrand2BeamsplitterCompatibilityJson {
        nodes {
          BeamSplitter
          Brand
          Device
          SKU
          Stripe_Product
          kit_product_id
          product_id
        }
      }
      brands2Adapter: allBrand2AdapterCompatibilityJson {
        nodes {
          Adapter
          Brand
          Device
          SKU
          Stripe_Product
          product_id
          kit_product_id
        }
      }
      upsellList: allUpsellProductsJson {
        nodes {
          description
          dropdown_data
          imageFolder
          name
          stripeName
        }
      }
    }
  `);
  const brands2Adapter = compatibilityData.brands2Adapter.nodes;
  const brands2Beamsplitter = compatibilityData.brands2Beamsplitter.nodes;
  const upsellList = compatibilityData.upsellList.nodes;

  function findStripeUpsellProduct(productName) {
    const stripeProduct = allStripeProducts.filter(
      (product) =>
        product.name.includes(productName) && !product.name.includes('MRC')
    );
    return stripeProduct;
  }

  function findCarrouselUpsellImages(imgFolder) {
    const carrousselImages = images.allFile.edges.filter((item) =>
      item.node.relativeDirectory.includes(imgFolder)
    );
    return carrousselImages;
  }

  const getDropdownData = (upsellItem) => {
    return upsellItem.dropdown_data === 'brands2Adapter'
      ? brands2Adapter
      : brands2Beamsplitter;
  };

  return (
    <Box sx={{ padding: 5 }}>
      <Container maxWidth={'lg'}>
        <Grid container marginTop={2}>
          <Grid item xs={12} padding={{ xs: 1, sm: 3 }}>
            <Typography
              variant="h5"
              sx={{ marginBottom: 4, fontWeight: 600 }}
              gutterBottom={true}
            >
              You may also want to check&nbsp;out...
            </Typography>
          </Grid>
          <Grid
            container
            // sx={{ flexGrow: 1 }}
            justifyContent={'space-evenly'}
            // alignItems={'flex-start'}
            spacing={2}
          >
            {upsellList.map((item) => {
              return item.dropdown_data === null ? (
                <ProductUpsellCard
                  name={item.name}
                  product={findStripeUpsellProduct(item.stripeName)}
                  description={item.description}
                  images={findCarrouselUpsellImages(item.imageFolder)}
                />
              ) : (
                <ProductUpsellCardWithDropdown
                  name={item.name}
                  products={findStripeUpsellProduct(item.stripeName)}
                  description={item.description}
                  images={findCarrouselUpsellImages(item.imageFolder)}
                  data={getDropdownData(item)}
                />
              );
            })}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ProductsUpsellSection;
