import React from 'react';

import CarouselMiniatures from '../carousel/carousel-with-miniatures';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { formatCurrencyString } from 'use-shopping-cart';

import BrandSelect from '../brand-selection';

const ProductUpsellCardWithDropdown = ({
  products,
  name,
  description,
  images,
  data,
  ...props
}) => {
  const responsive = {
    mobile: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  };

  return (
    <Grid
      item
      container
      sm={5}
      md={3}
      spacing={3}
      rowSpacing={4}
      marginBottom={{ xs: 6, md: 0 }}
      // item
      // container
      // sm={5}
      // md={3}
      // spacing={3}
      // rowSpacing={4}
      // marginBottom={6}
      // // flexDirection={'column'}
      // // flexWrap={'no-wrap'}
      // sx={{ flexDirection: 'column', flexWrap: 'no-wrap' }}
    >
      <Grid item xs={12}>
        <CarouselMiniatures data={images} />
      </Grid>

      {/* <Grid container item> */}
      {/* <Grid
        item
        container
        justifyContent={'space-between'}
        // alignItems={'center'}
        // marginBottom={1}
      > */}
      <Grid item xs={7}>
        <Typography variant="h6"> {name} </Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography variant="h6">
          {formatCurrencyString({
            value: products[0].price,
            currency: products[0].currency,
          })}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="body2"
          textAlign={'justify'}
          gutterBottom={true}
          marginBottom={2}
        >
          {description}
        </Typography>
      </Grid>
      {/* </Grid> */}
      <Grid item>
        <BrandSelect
          productName={name}
          data={data}
          stripeProducts={products}
          page={'upsell-page'}
        />
      </Grid>
      {/* </Grid> */}
    </Grid>
  );
};

export default ProductUpsellCardWithDropdown;
