import React from 'react';
// import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Button } from 'gatsby-theme-material-ui';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

// import Accordion from '@mui/material/Accordion';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} round {...props} />
))(({ theme }) => ({
  //   border: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  //   '&:not(:last-child)': {
  //     borderBottom: 0,
  //   },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  // backgroundColor: '#F9F9F9',
  //   theme.palette.mode === 'dark'
  //     ? 'rgba(255, 255, 255, .05)'
  //     : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    color: '#FFA100',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function FAQsSectionBeamSplitter() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const beamSplitterQuery = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativePath: { eq: "images/beamsplitter-microscope.jpeg" } }
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData(width: 300, layout: CONSTRAINED)
          }
        }
      }
    }
  `);
  const beamSplitterImage = getImage(beamSplitterQuery.allFile.nodes[0]);

  return (
    <Container
      sx={{
        // backgroundColor: '#F9F9F9',
        paddingTop: 10,
        paddingBottom: 10,
        marginTop: 1,
      }}
      maxWidth={'100%'}
    >
      <Grid
        container
        spacing={2}
        // sx={{
        //   backgroundColor: '#F9F9F9',
        //   paddingTop: 10,
        //   paddingBottom: 10,
        //   marginTop: 1,
        // }}
        maxWidth={'100%'}
        justifyContent="space-evenly"
      >
        <Grid item>
          <Accordion
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography sx={{ flexShrink: 1 }}>
                How do I know if my microscope or slit lamp has a Beam Splitter?
              </Typography>
              {/* <Typography sx={{ color: 'text.secondary' }}>
              You are currently not an owner
            </Typography> */}
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ padding: 1 }}>
                The beam splitter is usually located{' '}
                <span style={{ fontWeight: 'bold' }}>
                  between the binoculars and the optics of the microscope or
                  ophthalmic slit lamp.{' '}
                </span>{' '}
                It has either one or two ports to connect cameras or secondary
                observers.
              </Typography>
              <div
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <GatsbyImage
                  image={beamSplitterImage}
                  fadeIn={false}
                  loading="eager"
                  alt="Beam Splitter Image"
                />
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ flexShrink: 1 }}>
                What is a Beam Splitter?
              </Typography>
              {/* <Typography sx={{ color: 'text.secondary' }}>
                  </Typography> */}
            </AccordionSummary>
            <AccordionDetails>
              <Typography gutterBottom={true} sx={{ padding: 1 }}>
                A beam splitter is an attachment that captures a beam of light
                from a microscope and directs it to a smartphone. This is what
                makes it possible for the MicroREC to show on your phone screen,
                the same thing you are seeing with your own eyes! <br />
                <span style={{ color: '#FFA100' }}>
                  Check this video if you want learn more.
                </span>
              </Typography>
              <div
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <a
                  href="https://www.youtube.com/watch?v=3NymwEe8Br4"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <Button color="primary" variant="contained">
                    {' '}
                    Go to Video!{' '}
                  </Button>
                </a>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'panel3'}
            onChange={handleChange('panel3')}
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography sx={{ flexShrink: 1 }}>
                Do I need a Beam Splitter?
              </Typography>
              {/* <Typography sx={{ color: 'text.secondary' }}>
              Filtering has been entirely disabled for whole web server
            </Typography> */}
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ padding: 1 }}>
                The MicroREC allows to enjoy the full potential of your
                microscope or slit lamp. Our unique optical system will allow
                you to:
                <ul>
                  <li style={{ marginBottom: '1em' }}>
                    <span style={{ color: '#8F4FFF', fontWeight: 'bold' }}>
                      You already have a Beam Splitter
                    </span>{' '}
                    <br />
                    If your microscope or slitlamp already has a Beam Splitter
                    you <span style={{ fontWeight: 'bold' }}>do not </span> need
                    another one from us.
                  </li>
                  <li style={{ marginBottom: '1em' }}>
                    <span style={{ color: '#8F4FFF', fontWeight: 'bold' }}>
                      You don't have a Beam Splitter
                    </span>{' '}
                    <br />
                    If your microscope or slitlamp does not have a Beam
                    Splitter, please{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {' '}
                      make sure you buy it{' '}
                    </span>{' '}
                    when you buy the MicroREC. You{' '}
                    <span style={{ fontWeight: 'bold' }}> need one </span>to be
                    able to use the MicroREC
                  </li>
                  <li style={{ marginBottom: '1em' }}>
                    <span style={{ color: '#8F4FFF', fontWeight: 'bold' }}>
                      You are still not sure
                    </span>{' '}
                    <br />
                    Get in touch with us and we will help you!
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === 'panel3_1'}
            onChange={handleChange('panel3_1')}
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography sx={{ flexShrink: 1 }}>
                Is the Custom Surgical's Beam Splitter compatible with my
                microscope?
              </Typography>
              {/* <Typography sx={{ color: 'text.secondary' }}>
              Filtering has been entirely disabled for whole web server
            </Typography> */}
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ padding: 1 }}>
                Right now our Beam Splitter is compatible with most microscopes.
                Below you can see a comprehensive list of the brands. We are
                always working to increase the compatibility with more devices,
                if your brand is not on the image, please contact us so we can
                give you more info!
              </Typography>
              <Grid container>
                <Grid item container alignItems={'center'}>
                  <Grid item xs={12} sm={4}>
                    <Typography sx={{ padding: 1, fontWeight: 'bold' }}>
                      MicroREC Kit with Beam Splitter for Leica
                    </Typography>
                  </Grid>
                  <Divider
                    orientation="vertical"
                    flexItem
                    display={{ xs: 'none', sm: 'flex' }}
                  />
                  <Grid item xs={12} sm={7}>
                    <Typography sx={{ padding: 1 }}>
                      <ul>
                        <li style={{ marginBottom: '1em' }}>
                          Leica: M320, M500, M501, M561, M220, M620, M822, M844
                        </li>
                      </ul>
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ width: '100%' }} />
                <Grid item container alignItems={'center'}>
                  <Grid item xs={12} sm={4}>
                    <Typography sx={{ padding: 1, fontWeight: 'bold' }}>
                      MicroREC Kit with Beam Splitter for Zeiss
                    </Typography>
                  </Grid>
                  <Divider
                    orientation="vertical"
                    flexItem
                    display={{ xs: 'none', sm: 'flex' }}
                  />
                  <Grid item xs={12} sm={7}>
                    <Typography sx={{ padding: 1 }}>
                      <ul>
                        <li style={{ marginBottom: '1em' }}>
                          Zeiss: Lumera i, Lumera T, Lumera 700, Artevo 800,
                          OPMI pico, OPMI visu 210
                        </li>
                        <li style={{ marginBottom: '1em' }}>ALCON: LuxOR</li>
                        <li style={{ marginBottom: '1em' }}>TOPCON: OMS-800</li>
                        <li style={{ marginBottom: '1em' }}>
                          Takagi: OM-6, OM-9, OM-19
                        </li>
                        <li style={{ marginBottom: '1em' }}>
                          Zumax: OMS3200, OMS2360, OMS2380, OMS2350
                        </li>
                        <li style={{ marginBottom: '1em' }}>
                          {' '}
                          Shin Nippon: OP-2{' '}
                        </li>
                        <li style={{ marginBottom: '1em' }}>Opto DM: PRO IB</li>
                        <li style={{ marginBottom: '1em' }}>Altion: AM-4000</li>
                        <li style={{ marginBottom: '1em' }}>
                          CJ: Optik Flexion
                        </li>
                        <li style={{ marginBottom: '1em' }}>Ecleris: OM-100</li>
                      </ul>
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ width: '100%' }} />
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === 'panel5'}
            onChange={handleChange('panel5')}
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5bh-content"
              id="panel5bh-header"
            >
              <Typography sx={{ flexShrink: 1 }}>
                Is the Custom Surgical's Beam Splitter compatible with my slit
                lamp?
              </Typography>
              {/* <Typography sx={{ color: 'text.secondary' }}>
              Filtering has been entirely disabled for whole web server
            </Typography> */}
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ padding: 1 }}>
                Right now our Beam Splitter is compatible with most slit lamps.
                Below you can see a comprehensive list of the brands. We are
                always working to increase the compatibility with more devices,
                if your brand is not on the image, please contact us so we can
                give you more info!
              </Typography>
              <Grid container>
                <Grid item container alignItems={'center'}>
                  <Grid item xs={12} sm={4}>
                    <Typography sx={{ padding: 1, fontWeight: 'bold' }}>
                      MicroREC Kit with Beam Splitter for Zeiss
                    </Typography>
                  </Grid>
                  <Divider
                    orientation="vertical"
                    flexItem
                    display={{ xs: 'none', sm: 'flex' }}
                  />
                  <Grid item xs={12} sm={7}>
                    <Typography sx={{ padding: 1 }}>
                      <ul>
                        <li style={{ marginBottom: '1em' }}>
                          Zeiss: 100/16, 20SL, 30SL
                        </li>
                        <li style={{ marginBottom: '1em' }}>
                          Topcon: SL-D701, SL-D4, SL-D2, SL-D301, SL-2G, SL-D7,
                          SL-2ED, SL-7E, SL-8Z, Pascal Laser
                        </li>
                        <li style={{ marginBottom: '1em' }}>
                          {' '}
                          NIDEK: SL1800, SL 1600, SL 2000
                        </li>
                        <li style={{ marginBottom: '1em' }}>
                          MARCO: Ultra G2, Ultra G4, Ultra G5, Ultra G5-Zoom,
                          Ultra M2, Ultra M4, Ultra M5, Ultra B2
                        </li>
                        <li style={{ marginBottom: '1em' }}>
                          WOODLYN: HR3, HR5
                        </li>
                        <li style={{ marginBottom: '1em' }}> ELAN: 3, 5</li>
                        <li style={{ marginBottom: '1em' }}>
                          {' '}
                          BURTON: 2000, SL-3MH, SL-3MZ, SL-860
                        </li>
                        <li style={{ marginBottom: '1em' }}>
                          S4OPTIK: SL-H3, SL-H5, SL-HZ, SL-Z3, SL-Z5, SL-ZZ, H5
                          Elite
                        </li>
                        <li style={{ marginBottom: '1em' }}>
                          TAKAGI: SM-70N, SM-2, SM-4, SM-90
                        </li>
                        <li style={{ marginBottom: '1em' }}>
                          INAMI: Classicus L-0179 / L-0179 Z, Classicus L-0219,
                          L-0187 / L-0240, L-0189 / L-0229, L-0229-E
                        </li>
                        <li style={{ marginBottom: '1em' }}>
                          ARC LASER: CLASSIC 532, 514
                        </li>
                      </ul>
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ width: '100%' }} />
                <Grid item container alignItems={'center'}>
                  <Grid item xs={12} sm={4}>
                    <Typography sx={{ padding: 1, fontWeight: 'bold' }}>
                      MicroREC Kit with Beam Splitter for&nbsp;Haag-&nbsp;Streit
                    </Typography>
                  </Grid>
                  <Divider
                    orientation="vertical"
                    flexItem
                    display={{ xs: 'none', sm: 'flex' }}
                  />
                  <Grid item xs={12} sm={7}>
                    <Typography sx={{ padding: 1 }}>
                      <ul>
                        <li style={{ marginBottom: '1em' }}>
                          Haag-Streit Surgical: BQ 900
                        </li>
                      </ul>
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ width: '100%' }} />
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Container>
  );
}
