// Upsell page - for Beam Splitter especially but any other accesories as well.

import React, { useContext } from 'react';
import Container from '@mui/material/Container';
import CartModal from '../components/store/cartModal';
import SEO from '../components/seo';

import { myContext } from '../utils/provider';

import { ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

import { CartProvider } from 'use-shopping-cart/react';

import Layout from '../components/layout';
import AddedProductTopSection from '../sections_upsell/added-product';
import WarningBeamSplitterSection from '../sections_upsell/warning-beamsplitter';
import ProductsUpsellSection from '../sections_upsell/products-upsell-section';
import useStripeProducts from '../components/products/useStripeProducts';

import { useStaticQuery, graphql, navigate } from 'gatsby';

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== 'undefined';

let cs_theme = createTheme({
  palette: {
    primary: {
      main: '#FFA100',
    },
    secondary: {
      main: '#00C5B7',
    },
    white: '#FFFFFF',
    black: '#3D3838',
    purple: '#8F4FFF',
  },
  typography: {
    fontFamily: ['Red Hat Display'].join(','),
  },
});
cs_theme = responsiveFontSizes(cs_theme);

const UpsellPage = ({ location }) => {
  // This could be improve in the future with an endpoint that retrieves Upsell Products
  const allImages4upsell = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { regex: "/upsell-images/" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(width: 300, layout: CONSTRAINED)
            }
            name
            relativeDirectory
          }
        }
      }
    }
  `);
  const context = useContext(myContext);

  try {
    const products = useStripeProducts();
    const brandSelected = location.state.brand;
    const stripeItemAdded = location.state.stripeItem;
    const compatibleBrands = location.state.compatibleBrands;

    const getCurrency = () => {
      if (context.country === 'US') {
        return 'USD';
      } else {
        return 'EUR';
      }
    };
    return (
      <ThemeProvider theme={cs_theme}>
        <CartProvider
          stripe={process.env.STRIPE_PUBLISHABLE_KEY}
          currency={getCurrency()}
        >
          <Container
            id="accesories_page"
            disableGutters="true"
            maxWidth={false}
          >
            <Layout helmetName="Accesories">
              <SEO
                title="Custom Surgical Accesories Page"
                keywords={[`gatsby`, `application`, `react`]}
              />
              <CartModal />

              <AddedProductTopSection
                itemAdded={brandSelected}
                compatibilityList={compatibleBrands}
                stripeItemAdded={stripeItemAdded}
              />
              <WarningBeamSplitterSection
                allStripeProducts={products}
                adapterKitSelected={brandSelected}
                stripeAdapterKitAdded={stripeItemAdded}
              />
              <ProductsUpsellSection
                allStripeProducts={products}
                images={allImages4upsell}
              />
            </Layout>
          </Container>
        </CartProvider>
      </ThemeProvider>
    );
  } catch {
    if (isBrowser) {
      navigate('/');
    }
    return (
      <CartProvider stripe={process.env.STRIPE_PUBLISHABLE_KEY} currency="EUR">
        <Container id="accesories_page" disableGutters="true" maxWidth={false}>
          <Layout></Layout>
        </Container>
      </CartProvider>
    );
  }
};
export default UpsellPage;
