import React from 'react';

import CarouselMiniatures from '../carousel/carousel-with-miniatures';

import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Button } from 'gatsby-theme-material-ui';
import { useShoppingCart } from 'use-shopping-cart/react';
import { formatCurrencyString } from 'use-shopping-cart';

import { addItemToCartEventGA } from '../../utils/ga-events.js';
import { addItemToCartEventFB } from '../../utils/fb-events.js';

const ProductUpsellCard = ({
  product,
  name,
  description,
  images,
  ...props
}) => {
  const productStripe = product[0];
  // Get our functions from u-s-c
  const { addItem } = useShoppingCart();

  // Handle clicking the add item button
  const handleAddItem = () => {
    addItem(productStripe);
    addItemToCartEventGA(productStripe);
    addItemToCartEventFB(productStripe);
    // handleCartClick();
  };

  const responsive = {
    mobile: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  };

  return (
    <Grid
      item
      container
      sm={5}
      md={3}
      spacing={3}
      rowSpacing={4}
      marginBottom={{ xs: 6, md: 0 }}
      justifyContent={'center'}
    >
      <Grid item xs={12} margin={0}>
        <CarouselMiniatures data={images} />
      </Grid>

      {/* <Grid container item justifyContent={'center'}> */}

      <Grid item xs={7}>
        <Typography variant="h6" className={'productName'}>
          {' '}
          {name}{' '}
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography variant="h6">
          {formatCurrencyString({
            value: productStripe.price,
            currency: productStripe.currency,
          })}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body2"
          textAlign={'justify'}
          gutterBottom={true}
          marginBottom={2}
        >
          {description}
        </Typography>
      </Grid>

      <Grid item>
        <Box>
          <Button
            onClick={handleAddItem}
            color="primary"
            variant="contained"
            endIcon={<AddShoppingCartOutlinedIcon />}
          >
            Add to Cart
          </Button>
        </Box>
      </Grid>
      {/* </Grid> */}
    </Grid>
  );
};

export default ProductUpsellCard;
