import React from 'react';
// import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Button } from 'gatsby-theme-material-ui';
import { useShoppingCart } from 'use-shopping-cart/react';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { viewCartEventGA } from '../utils/ga-events';

const AddedProductTopSection = ({
  itemAdded,
  compatibilityList,
  stripeItemAdded,
  ...props
}) => {
  const { handleCartClick, cartDetails, totalPrice } = useShoppingCart();

  const handleClick = () => {
    handleCartClick();
    viewCartEventGA(cartDetails, totalPrice);
  };

  return (
    <Box
      sx={{
        backgroundColor: '#F9F9F9',
        marginTop: 10,
        marginBottom: 1,
      }}
      // maxWidth="100%"
    >
      <Container maxWidth={'lg'}>
        <Grid
          container
          padding={2}
          alignItems="center"
          justifyContent={'space-evenly'}
        >
          <Grid item xs={4} sm={2}>
            <GatsbyImage
              image={getImage(stripeItemAdded.image)} fadeIn={false} loading="eager"
              key={stripeItemAdded.displayName}
              width="80"
              imgStyle={{
                borderRadius: '5px',
              }}
              style={{
                borderRadius: '5px',
                boxShadow: ' 1px 1px 1px 2px rgba(0, 0, 0, 0.05)',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography textAlign={'left'}>
              <p>
                A MicroREC KIT for {itemAdded.Adapter} has been added to the
                Cart.
              </p>

              <p>
                This kit is also compatible with:
                <br />
                {compatibilityList.map((item, index) => (
                  <span> {(index ? ', ' : '') + item} </span>
                ))}{' '}
              </p>
            </Typography>
          </Grid>
          <Grid item xs={8} sm={4} md={3}>
            <Button onClick={handleClick} color="primary" variant="contained">
              REVIEW SHOPPING CART
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AddedProductTopSection;
